'use client'

import dynamic from 'next/dynamic'
import { ReactNode } from 'react'

const NavigatorComponent = dynamic(() => import('./navigator'), { ssr: false })
const HeaderComponent = dynamic(() => import('./header'), { ssr: false })

type Props = {
  children: ReactNode
  marquee?: boolean
}

export function LayoutContainer({ children, marquee = false }: Props) {
  // __STATE's

  // __RENDER
  return (
    <div className='ui--app-container'>
      <HeaderComponent />
      <main className='ui--router-view'>
        {marquee && (
          <div className='ui--page-message container'>
            <p className='animate-marquee text-nowrap px-4 text-sm'>
              MAXMA789 เว็บพนันออนไลน์ระดับสากล
              ที่ไม่ว่าท่านจะอยู่ที่ไหนท่านจะรู้สึกเหมือนอยู่ในคาสิโนสมัครวันนี้รับโปรโมชั่นสุดพิเศษมากมาย
              ฝาก-ถอน 1 วินาที
            </p>
          </div>
        )}

        {children}
      </main>
      <NavigatorComponent />
    </div>
  )
}
